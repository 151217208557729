import { FirebaseUser } from "@/models/users";
import { VenueData } from "@/models/venue";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Navigation } from "./common";
import ServiceController from "./service";

export default class VenuesController extends ServiceController {
  static async addVenue(
    venueDraft: VenueData,
    user: FirebaseUser
  ): Promise<boolean> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } as AxiosRequestConfig;
      await axios.post(
        `${VenuesController.HOST}/venues/add`,
        venueDraft,
        config
      );
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      VenuesController.translateError(axiosError);
      return false;
    }
  }

  static async updateVenue(
    venueId: number,
    venueDraft: VenueData,
    user: FirebaseUser
  ): Promise<boolean> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          venueId: venueId,
        },
      } as AxiosRequestConfig;
      await axios.put(
        `${VenuesController.HOST}/venues/update`,
        venueDraft,
        config
      );
      return true;
    } catch (error) {
      const axiosError = error as AxiosError;
      VenuesController.translateError(axiosError);
      return false;
    }
  }

  static async deleteVenue(venueId: number, user: FirebaseUser): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          venueId: venueId,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${VenuesController.HOST}/venues/remove`, headers);
      Navigation.goTo("Venues");
    } catch (error) {
      const axiosError = error as AxiosError;
      VenuesController.translateError(axiosError);
    }
  }

  static async fecthAll(user: FirebaseUser): Promise<VenueData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${VenuesController.HOST}/venues/admin/all`,
        config
      );
      const venues: VenueData[] = response.data.venues;
      return venues;
    } catch (error) {
      const axiosError = error as AxiosError;
      VenuesController.translateError(axiosError);
    }
  }
}
