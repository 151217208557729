
import { defineComponent } from "vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { Navigation } from "@/controllers/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Forbidden",
  setup() {
    useMeta({
      title: "No Autorizado",
      description: "No tienes permiso para acceder a esta ruta.",
    });
  },
  components: {
    BaseButton,
  },
  methods: {
    goHome(): void {
      Navigation.goTo("Login");
    },
  },
});
