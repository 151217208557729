<template>
  <BaseCard :actionable="true">
    <div class="card-grid">
      <div @click="editVenue">
        <h3 class="name">{{ data.name }}</h3>
        <h3 class="location">{{ data.city }}, {{ data.country }}.</h3>
      </div>
      <a-popconfirm
        placement="leftTop"
        title="¿Está seguro de que desea eliminar esta sede?"
        ok-text="Sí, eliminar sede"
        cancel-text="No, mantener sede"
        @confirm="deleteVenue"
      >
        <div class="action-button delete">
          <img src="@/assets/actions/delete-icon.svg" alt="Eliminar Usuario" />
        </div>
      </a-popconfirm>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { TableData } from "@/models/venue";

export default defineComponent({
  name: "VenueCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  emits: ["edit", "delete"],
  methods: {
    editVenue(): void {
      this.$emit("edit");
    },
    deleteVenue(): void {
      this.$emit("delete");
    },
  },
  computed: {},
});
</script>

<style scoped>
.cnt-card {
  font-size: 1.2rem;
}

.card-grid {
  grid-template-columns: 1fr min-content;
  display: grid;
}

.card-grid > div {
  text-align: center;
}

.card-grid > div > h3 {
  font-weight: var(--f-medium);
}

.card-grid > div > .location {
  text-align: center;
  font-weight: var(--f-semibold);
}
</style>
