import { DonationData } from "@/models/donation";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ServiceController from "./service";

export default class DonationsController extends ServiceController {
  static async fecthAll(
    user: FirebaseUser
  ): Promise<DonationData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${DonationsController.HOST}/payments/donations`,
        config
      );
      const venues: DonationData[] = response.data.donations;
      return venues;
    } catch (error) {
      const axiosError = error as AxiosError;
      DonationsController.translateError(axiosError);
    }
  }
}
