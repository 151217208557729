
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { TableData } from "@/models/venue";

export default defineComponent({
  name: "VenueCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  emits: ["edit", "delete"],
  methods: {
    editVenue(): void {
      this.$emit("edit");
    },
    deleteVenue(): void {
      this.$emit("delete");
    },
  },
  computed: {},
});
