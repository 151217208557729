type Currency = "COP" | "BRL" | "USD";
type DonationType = "ES" | "OF" | "DZ" | "MI";
type Identification = "CC" | "CE" | "TI" | "PAS" | "NIT";

export const DonationsColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: "15%",
    ellipsis: true,
    sorter: (a: TableData, b: TableData): number => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Identificación",
    dataIndex: "id",
    key: "id",
    width: "15%",
    ellipsis: true,
    sorter: (a: TableData, b: TableData): number => {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Donación",
    dataIndex: "donation",
    key: "donation",
    sorter: (a: TableData, b: TableData): number => {
      if (a.donation > b.donation) {
        return 1;
      }
      if (a.donation < b.donation) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    sorter: (a: TableData, b: TableData): number => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    defaultSortOrder: "descend",
    sorter: (a: TableData, b: TableData): number => {
      if (a.millis > b.millis) {
        return 1;
      }
      if (a.millis < b.millis) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Acción",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
    width: 100,
  },
];

export interface DonationView {
  name: string;
  email: string;
  date: string;
  donation: string;
  type: string;
  memberOf: string;
}

export interface DonationData {
  state: string;
  amount: number;
  currency: string;
  type: string;
  name: string;
  lastname: string;
  idType: string;
  id: number;
  email: string;
  country: string;
  memberOf: string;
  date: number;
}

export interface TableData {
  key?: number;
  name: string;
  id: number;
  donation: string;
  type: string;
  date: string;
  millis: number;
}

export default class Donation {
  state: string;
  amount: number;
  currency: Currency;
  type: DonationType;
  name: string;
  lastname: string;
  idType: Identification;
  id: number;
  email: string;
  country: string;
  memberOf: string;
  date: number;

  private readonly options = {
    AR: "Argentina",
    AU: "Australia",
    BR: "Brazil",
    CL: "Chile",
    CN: "China",
    CO: "Colombia",
    CZ: "Czechia",
    GH: "Ghana",
    HK: "Hong Kong",
    HU: "Hungary",
    IN: "India",
    ID: "Indonesia",
    JP: "Japan",
    KE: "Kenya",
    KR: "South Korea",
    MY: "Malaysia",
    MV: "Maldives",
    MX: "Mexico",
    MU: "Mauritius",
    NG: "Nigeria",
    PA: "Panama",
    PE: "Peru",
    PH: "Philippines",
    PL: "Poland",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    SG: "Singapore",
    SK: "Slovakia",
    ZA: "South Africa",
    LK: "Sri Lanka",
    TW: "Taiwan",
    TZ: "Tanzania",
    TH: "Thailand",
    TR: "Turkey",
    UG: "Uganda",
    VN: "Vietnam",
  };

  constructor({
    state,
    amount,
    currency,
    type,
    name,
    lastname,
    idType,
    id,
    email,
    country,
    memberOf,
    date,
  }: DonationData) {
    (this.state = state), (this.amount = amount);
    this.currency = currency as Currency;
    this.type = type as DonationType;
    this.name = name;
    this.lastname = lastname;
    this.idType = idType as Identification;
    this.id = id;
    this.email = email;
    this.country = country;
    this.memberOf = memberOf;
    this.date = date;
  }

  getCountry(): string {
    return this.options[this.country];
  }

  getState(): string {
    switch (this.state) {
      case "APPROVED":
        return "Aprobada";
      case "DECLINED":
        return "Declinada";
      case "EXPIRED":
        return "Expirada";
      default:
        return "Pendiente";
    }
  }

  getType(): string {
    switch (this.type) {
      case "ES":
        return "Espiga";
      case "DZ":
        return "Diezmo";
      case "MI":
        return "Misiones";
      default:
        return "Ofrenda";
    }
  }

  formatDate(): string {
    const date = new Date(this.date);
    const formatter = new Intl.DateTimeFormat("es-CO", {
      dateStyle: "short",
      timeStyle: "short",
    } as Intl.DateTimeFormatOptions);
    const formatedTime = formatter.format(date);
    return formatedTime.replace("a. m.", "AM").replace("p. m.", "PM");
  }

  getTableContent(): TableData {
    return {
      name: `${this.name} ${this.lastname}`,
      id: this.id,
      donation: `${this.amount} ${this.currency}`,
      type: this.getType(),
      date: this.formatDate(),
      millis: this.date,
    };
  }
}
