
import VenueCard from "@/components/find-us/VenueCard.vue";

import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import VenueEdition from "@/components/find-us/VenueEdit.vue";

import Venue, {
  TableData,
  VenueData,
  VenueRecord,
  VenuesColumns,
} from "@/models/venue";
import Authentication from "@/controllers/auth";

import VenuesController from "@/controllers/venues";
import { FirebaseUser } from "@/models/users";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import VenueLocations from "@/models/venue-locations";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "Sedes",
      description: "Lista de sedes del Centro Cristiano.",
    });
  },
  components: {
    VenueCard,
    BaseField,
    BaseButton,
    VenueEdition,
  },
  created() {
    if (this.currentUser) {
      this.fetchVenues();
    }
  },
  watch: {
    isMobile: {
      immediate: true,
      handler(): void {
        if (this.isMobile) {
          this.filterValue = "Colombia";
        }
      },
    },
  },
  data() {
    return {
      fetchingData: false,
      loadingState: false,
      filterValue: "",
      showModal: false,
      selectedId: -1,
      venueModel: {} as VenueData,
      venues: [] as Array<Venue>,
    };
  },
  methods: {
    addVenue(): void {
      this.venueModel = {
        country: "Colombia",
        state: "Cúcuta",
        city: "Cúcuta",
      } as VenueData;
      this.selectedId = -1;
      this.showModal = true;
    },
    editVenue(key: number): void {
      const venue = this.venues[key];
      this.venueModel = venue.data;
      this.selectedId = venue.id;
      this.showModal = true;
    },
    async modalAction(forUpdate: boolean): Promise<void> {
      this.loadingState = true;
      let result = false;
      if (forUpdate) {
        result = await this.updateVenue();
      } else {
        result = await this.postVenue();
      }
      this.loadingState = false;
      if (result) {
        this.showModal = false;
        this.fetchVenues();
      }
    },
    async fetchVenues(): Promise<void> {
      this.fetchingData = true;
      const venuesData = await VenuesController.fecthAll(this.currentUser);
      this.fetchingData = false;
      if (venuesData) {
        const data = venuesData as VenueRecord[];
        let venues: Venue[] = [];
        for (const venueRecord of data) {
          venues.push(new Venue(venueRecord, venueRecord.id));
        }
        this.venues = venues;
      }
    },
    async postVenue(): Promise<boolean> {
      const result = await VenuesController.addVenue(
        this.venueModel,
        this.currentUser
      );
      return result;
    },
    async updateVenue(): Promise<boolean> {
      const result = await VenuesController.updateVenue(
        this.selectedId,
        this.venueModel,
        this.currentUser
      );
      return result;
    },
    async deleteVenue(key: number): Promise<void> {
      this.fetchingData = true;
      const venue = this.venues[key];
      await VenuesController.deleteVenue(venue.id, this.currentUser);
      this.fetchVenues();
    },
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    countries() {
      return VenueLocations.countries;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    columns(): Array<Record<string, unknown>> {
      return VenuesColumns;
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const venue of this.venues) {
        var venueContent = venue.getTableContent();
        venueContent.key = key;
        dataSource.push(venueContent);
        key = key + 1;
      }
      return dataSource;
    },
    tableContent(): Array<TableData> {
      let rows: Array<TableData>;
      if (this.filterValue === "") {
        rows = this.dataSource;
      } else {
        rows = [];
        for (const data of this.dataSource) {
          const dataValue = this.isMobile ? data.country : data.name;
          if (
            dataValue.toLowerCase().includes(this.filterValue.toLowerCase())
          ) {
            rows.push(data);
          }
        }
        return rows;
      }
      return rows;
    },
    modalTitle(): string {
      return this.selectedId != -1 ? "Actualizar Sede" : "Nueva Sede";
    },
  },
});
