
import { defineComponent } from "vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "NotFound",
  setup() {
    useMeta({
      title: "404",
      description: "No se encontró la ruta solicitada.",
    });
  },
  components: {
    BaseButton,
  },
  methods: {
    goHome(): void {
      location.pathname = "/";
    },
  },
});
