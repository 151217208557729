
import MessageCard from "@/components/find-us/MessageCard.vue";

import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";

import MessageView from "@/components/find-us/MessageView.vue";

import PQRMessage, { MessagesColumns, TableData } from "@/models/message";
import PQRController from "@/controllers/messages";
import Authentication from "@/controllers/auth";
import { FirebaseUser } from "@/models/users";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "PQR",
      description: "Accede a los mensajes de los usuarios.",
    });
  },
  components: {
    MessageCard,
    BaseField,
    MessageView,
  },
  created() {
    this.fetchMessages();
  },
  data() {
    return {
      fetchingData: false,
      loadingState: false,
      filterName: "",
      showModal: false,
      selectedVenue: undefined as undefined | PQRMessage,
      messages: [] as PQRMessage[],
    };
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    columns(): Array<Record<string, unknown>> {
      return MessagesColumns;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    tableContent(): Array<TableData> {
      if (this.filterName === "") {
        return this.dataSource;
      } else {
        var rows: Array<TableData> = [];
        for (const data of this.dataSource) {
          if (
            (data.name as string)
              .toLowerCase()
              .includes(this.filterName.toLowerCase())
          ) {
            rows.push(data);
          }
        }
        return rows;
      }
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const message of this.messages) {
        var messageContent = message.getTableContent();
        messageContent.key = key;
        dataSource.push(messageContent);
        key = key + 1;
      }
      return dataSource;
    },
    modalTitle(): string {
      return this.selectedVenue ? this.selectedVenue.getType() : "";
    },
  },
  methods: {
    async fetchMessages() {
      this.fetchingData = true;
      this.messages = [];
      const messagesData = await PQRController.fecthAll(this.currentUser);
      this.fetchingData = false;
      if (messagesData) {
        let messages: PQRMessage[] = [];
        for (const message of messagesData) {
          messages.push(new PQRMessage(message));
        }
        this.messages = messages;
      }
    },
    async modalAction(messageId: number): Promise<void> {
      this.loadingState = true;
      await PQRController.deleteVenue(messageId, this.currentUser);
      this.loadingState = false;
      this.showModal = false;
      this.fetchMessages();
    },
    showMessage(key: number): void {
      this.selectedVenue = this.messages[key];
      this.showModal = true;
    },
  },
});
