type ContactType = "P" | "Q" | "R";

export const MessagesColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: "15%",
    ellipsis: true,
    defaultSortOrder: "ascend",
    sorter: (a: TableData, b: TableData): number => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    width: "15%",
    ellipsis: true,
    sorter: (a: TableData, b: TableData): number => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Mensaje",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
  },
  {
    title: "Acción",
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
    width: 100,
  },
];

export interface TableData {
  key?: number;
  name: string;
  type: string;
  message: string;
}

export interface MessageData {
  name: string;
  email: string;
  phone: string;
  type: ContactType;
  message: string;
  timestamp: number;
  id: string;
}

export default class PQRMessage {
  name: string;
  email: string;
  phone: string;
  type: ContactType;
  message: string;
  timestamp: number;
  reference: string;

  constructor({
    name,
    email,
    phone,
    type,
    message,
    timestamp,
    id,
  }: MessageData) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.type = type;
    this.message = message;
    this.timestamp = timestamp;
    this.reference = id;
  }

  getType(): string {
    switch (this.type) {
      case "Q":
        return "Queja";
      case "R":
        return "Reclamo";
      default:
        return "Pregunta";
    }
  }

  getTableContent(): TableData {
    return {
      name: this.name,
      type: this.getType(),
      message: this.message,
    };
  }
}
