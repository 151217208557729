
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";

import DonationView from "@/components/finance/DonationView.vue";

import Donation, { DonationsColumns, TableData } from "@/models/donation";
import DonationsController from "@/controllers/donations";
import { FirebaseUser } from "@/models/users";
import Authentication from "@/controllers/auth";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import RecordCard from "@/components/finance/RecordCard.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "Donaciones",
      description: "Accede a los registros de donaciones.",
    });
  },
  components: {
    BaseField,
    DonationView,
    RecordCard,
  },
  created() {
    this.fetchDonations();
  },
  data() {
    return {
      fetchingData: false,
      filterName: "",
      showModal: false,
      selectedDonation: undefined as undefined | Donation,
      donations: [] as Array<Donation>,
    };
  },
  methods: {
    async fetchDonations() {
      this.fetchingData = true;
      this.donations = [];
      const donationsData = await DonationsController.fecthAll(
        this.currentUser
      );
      if (donationsData) {
        let donations: Donation[] = [];
        for (const message of donationsData) {
          donations.push(new Donation(message));
        }
        this.donations = donations;
      }
      this.fetchingData = false;
    },
    showDonation(key: number): void {
      this.selectedDonation = this.donations[key];
      this.showModal = true;
    },
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    columns(): Array<Record<string, unknown>> {
      return DonationsColumns;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const donation of this.donations) {
        var donationContent = donation.getTableContent();
        donationContent.key = key;
        dataSource.push(donationContent);
        key = key + 1;
      }
      return dataSource;
    },
    tableContent(): Array<TableData> {
      let rows: Array<TableData>;
      if (this.filterName === "") {
        rows = this.dataSource;
      } else {
        rows = [];
        for (const data of this.dataSource) {
          if (data.id.toString().includes(this.filterName)) {
            rows.push(data);
          }
        }
      }
      return this.isMobile ? rows.slice(0, 15) : rows;
    },
    modalTitle(): string {
      return this.selectedDonation
        ? `${this.selectedDonation.getState()} - ${this.selectedDonation.formatDate()}`
        : "";
    },
  },
});
