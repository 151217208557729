<template>
  <form action="" method="post" class="cc-form">
    <BaseField
      class="all"
      v-model="donationData.name"
      type="text"
      label="Nombre:"
      :readonly="true"
    />
    <BaseField
      class="half"
      v-model="donationData.email"
      type="text"
      label="Correo electrónico:"
      :readonly="true"
    />
    <BaseField
      class="half"
      v-model="donorId"
      type="text"
      label="Identificación:"
      :readonly="true"
    />

    <BaseField
      class="half"
      v-model="amount"
      type="text"
      label="Cantidad:"
      :readonly="true"
    />
    <BaseField
      class="half"
      v-model="donationData.type"
      type="text"
      label="Tipo:"
      :readonly="true"
    />
    <BaseField
      class="half"
      v-model="country"
      type="text"
      label="País:"
      :readonly="true"
    />
    <BaseField
      class="half"
      v-model="donationData.memberOf"
      type="text"
      label="Miembro de:"
      :readonly="true"
    />
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import Donation, { DonationView } from "@/models/donation";

export default defineComponent({
  name: "VenueEdition",
  components: {
    BaseField,
  },
  props: {
    data: {
      type: Object as PropType<Donation>,
      default: () => {
        return {};
      },
    },
  },
  beforeUpdate() {
    this.donationData = this.formatData();
  },
  data() {
    return {
      donationData: this.formatData(),
    };
  },
  computed: {
    amount(): string {
      return `${this.data.currency} ${this.data.amount}`;
    },
    donorId(): string {
      return `${this.data.idType} ${this.data.id}`;
    },
    country(): string {
      return this.data.getCountry();
    },
  },
  methods: {
    formatData(): DonationView {
      const data = this.$props.data;
      return {
        name: `${data.name} ${data.lastname}`,
        email: data.email,
        date: data.formatDate(),
        donation: `${data.amount} ${data.currency}`,
        type: data.getType(),
        memberOf: data.memberOf,
      };
    },
  },
});
</script>
