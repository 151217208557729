<template>
  <a-result
    status="403"
    title="403"
    sub-title="Lo sentimos, no tienes permisos para acceder a esta página."
  >
    <template #extra>
      <BaseButton text="Ir a Inicio" @click="goHome" />
    </template>
  </a-result>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { Navigation } from "@/controllers/common";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Forbidden",
  setup() {
    useMeta({
      title: "No Autorizado",
      description: "No tienes permiso para acceder a esta ruta.",
    });
  },
  components: {
    BaseButton,
  },
  methods: {
    goHome(): void {
      Navigation.goTo("Login");
    },
  },
});
</script>
