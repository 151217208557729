<template>
  <div class="pqr-title">
    <h1 class="global-title">Donaciones</h1>
    <BaseField
      class="filter-field"
      v-model="filterName"
      type="number"
      placeholder="Cedula"
    />
  </div>
  <a-spin :spinning="fetchingData">
    <div class="desktop-table" v-if="!isMobile">
      <a-table
        :dataSource="tableContent"
        :columns="columns"
        :pagination="{ defaultPageSize: 50, size: 'small' }"
      >
        <template #action="{ record }">
          <a class="table-action" @click="showDonation(record.key)">Ver</a>
        </template>
      </a-table>
    </div>
    <div class="mobile-cards animated-container" v-else>
      <RecordCard
        v-for="(record, index) in tableContent"
        :key="index"
        :data="record"
        @click="showDonation(index)"
      />
    </div>
  </a-spin>
  <a-modal
    v-model:visible="showModal"
    :title="modalTitle"
    :destroyOnClose="true"
    :footer="null"
  >
    <DonationView :data="selectedDonation" />
  </a-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BaseField from "@/components/common/BaseField.vue";

import DonationView from "@/components/finance/DonationView.vue";

import Donation, { DonationsColumns, TableData } from "@/models/donation";
import DonationsController from "@/controllers/donations";
import { FirebaseUser } from "@/models/users";
import Authentication from "@/controllers/auth";
import { ResponsiveManager } from "@/controllers/common";
import { ScreenType } from "@/models/common";
import RecordCard from "@/components/finance/RecordCard.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "Staff",
  setup() {
    useMeta({
      title: "Donaciones",
      description: "Accede a los registros de donaciones.",
    });
  },
  components: {
    BaseField,
    DonationView,
    RecordCard,
  },
  created() {
    this.fetchDonations();
  },
  data() {
    return {
      fetchingData: false,
      filterName: "",
      showModal: false,
      selectedDonation: undefined as undefined | Donation,
      donations: [] as Array<Donation>,
    };
  },
  methods: {
    async fetchDonations() {
      this.fetchingData = true;
      this.donations = [];
      const donationsData = await DonationsController.fecthAll(
        this.currentUser
      );
      if (donationsData) {
        let donations: Donation[] = [];
        for (const message of donationsData) {
          donations.push(new Donation(message));
        }
        this.donations = donations;
      }
      this.fetchingData = false;
    },
    showDonation(key: number): void {
      this.selectedDonation = this.donations[key];
      this.showModal = true;
    },
  },
  computed: {
    isMobile(): boolean {
      var screen = ResponsiveManager.retrieveScreenSize();
      return screen === ScreenType.mobile;
    },
    columns(): Array<Record<string, unknown>> {
      return DonationsColumns;
    },
    currentUser(): FirebaseUser {
      return Authentication.currentUser();
    },
    dataSource(): Array<TableData> {
      var key = 0;
      var dataSource: Array<TableData> = [];
      for (const donation of this.donations) {
        var donationContent = donation.getTableContent();
        donationContent.key = key;
        dataSource.push(donationContent);
        key = key + 1;
      }
      return dataSource;
    },
    tableContent(): Array<TableData> {
      let rows: Array<TableData>;
      if (this.filterName === "") {
        rows = this.dataSource;
      } else {
        rows = [];
        for (const data of this.dataSource) {
          if (data.id.toString().includes(this.filterName)) {
            rows.push(data);
          }
        }
      }
      return this.isMobile ? rows.slice(0, 15) : rows;
    },
    modalTitle(): string {
      return this.selectedDonation
        ? `${this.selectedDonation.getState()} - ${this.selectedDonation.formatDate()}`
        : "";
    },
  },
});
</script>

<style scoped>
.pqr-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.filter-field {
  width: 40%;
}

.button-cnt {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .filter-field {
    width: 15%;
  }
}
</style>
