export const VenuesColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    defaultSortOrder: "ascend",
    sorter: (a: TableData, b: TableData): number => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Pastor(es)",
    dataIndex: "leadership",
    key: "leadership",
    ellipsis: true,
  },
  {
    title: "Dirección",
    dataIndex: "address",
    key: "address",
    ellipsis: true,
  },
  {
    title: "País",
    dataIndex: "country",
    key: "country",
    ellipsis: true,
    width: "12%",
    sorter: (a: TableData, b: TableData): number => {
      if (a.country > b.country) {
        return 1;
      }
      if (a.country < b.country) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
    ellipsis: true,
    width: "12%",
    sorter: (a: TableData, b: TableData): number => {
      if (a.state > b.state) {
        return 1;
      }
      if (a.state < b.state) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    title: "Ciudad",
    dataIndex: "city",
    key: "city",
    ellipsis: true,
    width: "12%",
    sorter: (a: TableData, b: TableData): number => {
      if (a.city > b.city) {
        return 1;
      }
      if (a.city < b.city) {
        return -1;
      }
      // a must be equal to b
      return 0;
    },
  },
  {
    dataIndex: "action",
    key: "action",
    slots: { customRender: "action" },
    width: "8%",
  },
  {
    title: "",
    dataIndex: "deletion",
    key: "deletion",
    slots: { customRender: "deletion" },
    width: "10%",
  },
];

export interface TableData {
  key?: number;
  name: string;
  leadership: string;
  country: string;
  state: string;
  city: string;
  address: string;
}

export interface VenueData {
  name: string;
  leadership: Array<string>;
  address: string;
  country: string;
  state: string;
  city: string;
  phone: string;
  website: string;
  latitude: number;
  longitude: number;
}

export interface VenueRecord extends VenueData {
  id: number;
}

export default class Venue {
  name: string;
  leadership: Array<string>;
  address: string;
  country: string;
  state: string;
  city: string;
  phone: string;
  website: string;
  latitude: number;
  longitude: number;
  id: number;

  constructor(data: VenueData, venueId: number) {
    this.name = data.name;
    this.leadership = data.leadership;
    this.address = data.address;
    this.country = data.country;
    this.state = data.state;
    this.city = data.city;
    this.phone = data.phone;
    this.website = data.website;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.id = venueId;
  }

  getTableContent(): TableData {
    return {
      name: this.name,
      leadership: this.leadership.join(", "),
      country: this.country,
      state: this.state,
      city: this.city,
      address: this.address,
    };
  }

  get data(): VenueData {
    return {
      name: this.name,
      leadership: this.leadership,
      address: this.address,
      country: this.country,
      state: this.state,
      city: this.city,
      phone: this.phone,
      website: this.website,
      latitude: this.latitude,
      longitude: this.longitude,
    };
  }
}
