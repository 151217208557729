
import { defineComponent, PropType } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import PQRMessage from "@/models/message";

export default defineComponent({
  name: "VenueEdition",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    data: {
      type: Object as PropType<PQRMessage>,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      messageData: this.$props.data,
    };
  },
  emits: ["deleteMessage"],
  methods: {
    handleAction(event: Event): void {
      event.preventDefault();
      this.$emit("deleteMessage", this.messageData.reference);
    },
    goToEmail() {
      const anchor = document.createElement("a");
      anchor.href = `mailto:${this.messageData.email}`;
      anchor.rel = "noopener noreferrer";
      anchor.target = "_blank";
      anchor.click();
    },
    goToWhatsapp() {
      const anchor = document.createElement("a");
      anchor.href = `https://wa.me/57${this.messageData.phone}`;
      anchor.target = "_blank";
      anchor.click();
    },
  },
});
