<template>
  <a-spin :spinning="loading" :delay="250">
    <form action="" method="post" class="cc-form">
      <BaseField
        class="all"
        v-model="messageData.name"
        type="text"
        label="Nombre:"
        :readonly="true"
      />
      <BaseField
        class="half anchor-text"
        v-model="messageData.email"
        type="text"
        label="Correo electrónico:"
        :readonly="true"
        @click="goToEmail"
      />
      <BaseField
        class="half anchor-text"
        v-model="messageData.phone"
        type="text"
        label="Teléfono:"
        :readonly="true"
        @click="goToWhatsapp"
      />
      <BaseField
        class="all"
        v-model="messageData.message"
        type="multiline"
        label="Mensaje:"
        :rows="6"
        :readonly="true"
      />
      <BaseButton text="Eliminar  " class="all columns" @click="handleAction" />
    </form>
  </a-spin>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import PQRMessage from "@/models/message";

export default defineComponent({
  name: "VenueEdition",
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    data: {
      type: Object as PropType<PQRMessage>,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      messageData: this.$props.data,
    };
  },
  emits: ["deleteMessage"],
  methods: {
    handleAction(event: Event): void {
      event.preventDefault();
      this.$emit("deleteMessage", this.messageData.reference);
    },
    goToEmail() {
      const anchor = document.createElement("a");
      anchor.href = `mailto:${this.messageData.email}`;
      anchor.rel = "noopener noreferrer";
      anchor.target = "_blank";
      anchor.click();
    },
    goToWhatsapp() {
      const anchor = document.createElement("a");
      anchor.href = `https://wa.me/57${this.messageData.phone}`;
      anchor.target = "_blank";
      anchor.click();
    },
  },
});
</script>

<style scoped>
.anchor-text:deep() .input {
  cursor: pointer !important;
}
</style>
