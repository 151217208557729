<template>
  <BaseCard :actionable="true">
    <div>
      <div class="record-info">
        <h3 class="name">{{ data.name }}</h3>
        <h3 class="amount">{{ data.donation }}</h3>
        <h3 class="id">{{ data.id }}</h3>
        <h3 class="type">{{ data.type }}</h3>
      </div>
      <h3 class="date">{{ data.date }}</h3>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { Navigation, Store } from "@/controllers/common";
import { TableData } from "@/models/donation";

export default defineComponent({
  name: "RecordCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  methods: {
    editUser(): void {
      Store.update("edition/storeUser", this.data);
      Navigation.goTo("EditUser");
    },
  },
  computed: {},
});
</script>

<style scoped>
.cnt-card {
  font-size: 1.2rem;
}

.record-info {
  margin-bottom: 0.5rem;
  grid-template-columns: 50% 50%;
  text-align: center;
  display: grid;
}

.record-info > h3 {
  font-weight: var(--f-medium);
  margin: 0;
}

.record-info > h3:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.record-info > h3:nth-child(3),
.record-info > h3:nth-child(4) {
  color: var(--c-silver-chalice);
}

.record-info + .date {
  text-align: center;
  font-weight: var(--f-semibold);
  margin: 0;
}
</style>
