<template>
  <BaseCard :actionable="true">
    <div>
      <h3 class="name">{{ data.name }}</h3>
      <h3 class="message">{{ data.message }}</h3>
      <h3 class="type">{{ data.type }}</h3>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { TableData } from "@/models/message";

export default defineComponent({
  name: "VenueCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  computed: {},
});
</script>

<style scoped>
.cnt-card {
  font-size: 1.2rem;
}

.name {
  text-align: center;
  color: var(--c-silver-chalice);
  font-weight: var(--f-semibold);
}

.message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type {
  text-align: center;
  font-weight: var(--f-semibold);
}

.card-grid {
  grid-template-columns: 1fr min-content;
  display: grid;
}

.card-grid > div {
  text-align: center;
}

.card-grid > div > h3 {
  font-weight: var(--f-medium);
}

.card-grid > div > .message {
  text-align: center;
  font-weight: var(--f-semibold);
}
</style>
