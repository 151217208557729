<template>
  <a-result
    status="404"
    title="404"
    sub-title="Lo sentimos, la página que visitaste no existe."
  >
    <template #extra>
      <BaseButton text="Ir a Inicio" @click="goHome" />
    </template>
  </a-result>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { useMeta } from "vue-meta";

export default defineComponent({
  name: "NotFound",
  setup() {
    useMeta({
      title: "404",
      description: "No se encontró la ruta solicitada.",
    });
  },
  components: {
    BaseButton,
  },
  methods: {
    goHome(): void {
      location.pathname = "/";
    },
  },
});
</script>
