export default abstract class VenueLocations {
  private static readonly _countries = [
    "Argentina",
    "Bután",
    "Colombia",
    "Ecuador",
    "España",
    "Estados Unidos",
    "India",
    "Italia",
    "Nepal",
    "Suiza",
    "Venezuela",
  ];

  private static readonly _states = {
    Argentina: [
      "Ciudad Autónoma de Buenos Aires",
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ],
    Bután: [
      "Bumthang",
      "Chukha",
      "Dagana",
      "Gasa",
      "Haa",
      "Lhuntse",
      "Mongar",
      "Paro",
      "Pemagatshel",
      "Punakha",
      "Samdrup Jongkhar",
      "Samtse",
      "Sarpang",
      "Thimphu",
      "Trashigang",
      "Trongsa",
      "Tsirang",
      "Wangdue Phodrang",
      "Zhemgang",
    ],
    Colombia: [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Archipiélago de San Andrés, Providencia y Santa Catalina",
      "Atlántico",
      "Bolívar",
      "Boyacá",
      "Caldas",
      "Caquetá",
      "Casanare",
      "Cauca",
      "Cesar",
      "Chocó",
      "Córdoba",
      "Cúcuta",
      "Cundinamarca",
      "Guainía",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Nariño",
      "Norte de Santander",
      "Putumayo",
      "Quindío",
      "Risaralda",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupés",
      "Vichada",
    ],
    Ecuador: [
      "Azuay",
      "Bolívar",
      "Cañar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galápagos",
      "Guayas",
      "Imbabura",
      "Los Ríos",
      "Manabí",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Santa Elena",
      "Santo Domingo de los Tsáchilas",
      "Sucumbíos",
      "Tungurahua",
      "Zamora-Chinchipe",
    ],
    España: [
      "A Coruña",
      "Albacete",
      "Alicante",
      "Almería",
      "Andalusia",
      "Araba Álava",
      "Aragon",
      "Badajoz",
      "Barcelona",
      "Biscay",
      "Burgos",
      "Cáceres",
      "Cádiz",
      "Cantabria",
      "Castellón",
      "Castile and León",
      "Castile-La Mancha",
      "Catalonia",
      "Ceuta",
      "Ciudad Real",
      "Comunidad de Madrid",
      "Comunidad Valenciana",
      "Córdoba",
      "Cuenca",
      "Extremadura",
      "Galicia",
      "Gipuzkoa",
      "Girona",
      "Granada",
      "Guadalajara",
      "Huelva",
      "Huesca",
      "Islas Baleares",
      "Islas Canarias",
      "Jaén",
      "La Rioja",
      "Las Palmas",
      "León",
      "Lleida",
      "Lugo",
      "Madrid",
      "Málaga",
      "Melilla",
      "Murcia",
      "Navarre",
      "Ourense",
      "País Vasco",
      "Palencia",
      "Pontevedra",
      "Provincia de Asturias",
      "Provincia de Ávila",
      "Región de Murcia",
      "Salamanca",
      "Santa Cruz de Tenerife",
      "Segovia",
      "Seville",
      "Soria",
      "Tarragona",
      "Teruel",
      "Toledo",
      "Valencia",
      "Valladolid",
      "Zamora",
      "Zaragoza",
    ],
    "Estados Unidos": [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "Baker Island",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Howland Island",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Jarvis Island",
      "Johnston Atoll",
      "Kansas",
      "Kentucky",
      "Kingman Reef",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Midway Atoll",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Navassa Island",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palmyra Atoll",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "United States Minor Outlying Islands",
      "United States Virgin Islands",
      "Utah",
      "Vermont",
      "Virginia",
      "Wake Island",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    India: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ],
    Italia: [
      "Abruzos",
      "Apulia",
      "Basilicata",
      "Calabria",
      "Campania",
      "Cerdeña",
      "Ciudad Metropolitana De Bari",
      "Ciudad Metropolitana De Bolonia",
      "Ciudad Metropolitana De Cagliari",
      "Ciudad Metropolitana De Catania",
      "Ciudad Metropolitana De Florencia",
      "Ciudad Metropolitana De Génova",
      "Ciudad Metropolitana De Messina",
      "Ciudad Metropolitana De Milán",
      "Ciudad Metropolitana De Nápoles",
      "Ciudad Metropolitana De Palermo",
      "Ciudad Metropolitana De Reggio Calabria",
      "Ciudad Metropolitana De Roma",
      "Ciudad Metropolitana De Turín",
      "Ciudad Metropolitana De Venecia",
      "Emilia-Romagna",
      "Friuli-Venezia Giulia",
      "Lazio",
      "Libero Consorzio Comunale Di Agrigento",
      "Libero Consorzio Comunale Di Caltanissetta",
      "Libero Consorzio Comunale Di Enna",
      "Libero Consorzio Comunale Di Ragusa",
      "Libero Consorzio Comunale Di Siracusa",
      "Libero Consorzio Comunale Di Trapani",
      "Liguria",
      "Lombardía",
      "Marche",
      "Molise",
      "Piamonte",
      "Provincia De Alessandria",
      "Provincia De Ancona",
      "Provincia De Ascoli Piceno",
      "Provincia De Asti",
      "Provincia De Avellino",
      "Provincia De Barletta-Andria-Trani",
      "Provincia De Belluno",
      "Provincia De Benevento",
      "Provincia De Bérgamo",
      "Provincia De Biella",
      "Provincia De Brescia",
      "Provincia De Brindisi",
      "Provincia De Campobasso",
      "Provincia De Carbonia-Iglesias",
      "Provincia De Caserta",
      "Provincia De Catanzaro",
      "Provincia De Chieti",
      "Provincia De Como",
      "Provincia De Cosenza",
      "Provincia De Cremona",
      "Provincia De Crotone",
      "Provincia De Cuneo",
      "Provincia De Fermo",
      "Provincia De Ferrara",
      "Provincia De Foggia",
      "Provincia De Forlì-Cesena",
      "Provincia De Frosinone",
      "Provincia De Gorizia",
      "Provincia De Grosseto",
      "Provincia De Imperia",
      "Provincia De Isernia",
      "Provincia De La Spezia",
      "Provincia De L'aquila",
      "Provincia De Latina",
      "Provincia De Lecce",
      "Provincia De Lecco",
      "Provincia De Livorno",
      "Provincia De Lodi",
      "Provincia De Lucca",
      "Provincia De Macerata",
      "Provincia De Mantua",
      "Provincia De Massa Y Carrara",
      "Provincia De Matera",
      "Provincia De Módena",
      "Provincia De Monza Y Brianza",
      "Provincia De Novara",
      "Provincia De Nuoro",
      "Provincia De Ogliastra",
      "Provincia De Olbia-Tempio",
      "Provincia De Oristano",
      "Provincia De Padua",
      "Provincia De Parma",
      "Provincia De Pavía",
      "Provincia De Perugia",
      "Provincia De Pesaro Y Urbino",
      "Provincia De Pescara",
      "Provincia De Piacenza",
      "Provincia De Pisa",
      "Provincia De Pistoia",
      "Provincia De Pordenone",
      "Provincia De Potenza",
      "Provincia De Prato",
      "Provincia De Rávena",
      "Provincia De Reggio Emilia",
      "Provincia De Rieti",
      "Provincia De Rimini",
      "Provincia De Rovigo",
      "Provincia De Salerno",
      "Provincia De Sassari",
      "Provincia De Savona",
      "Provincia De Siena",
      "Provincia De Sondrio",
      "Provincia De Taranto",
      "Provincia De Teramo",
      "Provincia De Terni",
      "Provincia De Treviso",
      "Provincia De Trieste",
      "Provincia De Udine",
      "Provincia De Varese",
      "Provincia De Verbano-Cusio-Ossola",
      "Provincia De Vercelli",
      "Provincia De Verona",
      "Provincia De Vibo Valentia",
      "Provincia De Vicenza",
      "Provincia De Viterbo",
      "Provincia Del Medio Campidano",
      "Sicilia",
      "Tirol Del Sur",
      "Toscana",
      "Trentino",
      "Trentino-Tirol Del Sur",
      "Umbría",
      "Valle De Aosta",
      "Veneto",
    ],
    Nepal: [
      "Región Central",
      "Región de Desarrollo del Lejano Oeste",
      "Región de Desarrollo Oriental",
      "Región del Medio Oeste",
      "Región Occidental",
      "Zona Bheri",
      "Zona De Bagmati",
      "Zona de Janakpur",
      "Zona De Sagarmatha",
      "Zona Dhaulagiri",
      "Zona Gandaki",
      "Zona Karnali",
      "Zona Kosi",
      "Zona Lumbini",
      "Zona Mahakali",
      "Zona Mechi",
      "Zona Narayani",
      "Zona Rapti",
      "Zona Seti",
    ],
    Suiza: [
      "Aargau",
      "Appenzell Ausserrhoden",
      "Appenzell Innerrhoden",
      "Basel-Landschaft",
      "Bern",
      "Fribourg",
      "Geneva",
      "Glarus",
      "Graubünden",
      "Jura",
      "Lucerne",
      "Neuchâtel",
      "Nidwalden",
      "Obwalden",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "St. Gallen",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Zürich",
    ],
    Venezuela: [
      "Amazonas",
      "Anzoátegui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolívar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Dependencias Federales de Venezuela",
      "Distrito Capital",
      "Falcón",
      "Guárico",
      "Lara",
      "Mérida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Táchira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia",
    ],
  };

  static get countries(): string[] {
    return VenueLocations._countries;
  }

  static getStates(country: string): string[] {
    return VenueLocations._states[country];
  }
}
