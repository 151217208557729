
import { defineComponent, PropType } from "vue";

import BaseCard from "@/components/common/BaseCard.vue";

import { Navigation, Store } from "@/controllers/common";
import { TableData } from "@/models/donation";

export default defineComponent({
  name: "RecordCard",
  components: {
    BaseCard,
  },
  props: {
    data: {
      type: Object as PropType<TableData>,
      required: true,
    },
  },
  data() {
    return {
      menuState: false,
    };
  },
  methods: {
    editUser(): void {
      Store.update("edition/storeUser", this.data);
      Navigation.goTo("EditUser");
    },
  },
  computed: {},
});
