import { MessageData } from "@/models/message";
import { FirebaseUser } from "@/models/users";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ServiceController from "./service";

export default class PQRController extends ServiceController {
  static async deleteVenue(pqrId: number, user: FirebaseUser): Promise<void> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const headers = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          pqrId: pqrId,
        },
      } as AxiosRequestConfig;
      await axios.delete(`${PQRController.HOST}/messages/remove`, headers);
    } catch (error) {
      const axiosError = error as AxiosError;
      PQRController.translateError(axiosError);
    }
  }

  static async fecthAll(
    user: FirebaseUser
  ): Promise<MessageData[] | undefined> {
    try {
      const token = (await user?.getIdToken()) ?? "";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      } as AxiosRequestConfig;
      const response = await axios.get(
        `${PQRController.HOST}/messages/all`,
        config
      );
      const messages: MessageData[] = response.data.messages;
      return messages;
    } catch (error) {
      const axiosError = error as AxiosError;
      PQRController.translateError(axiosError);
    }
  }
}
