
import { VenueData } from "@/models/venue";
import { defineComponent, PropType } from "vue";
import BaseField from "@/components/common/BaseField.vue";
import BaseButton from "@/components/common/BaseButton.vue";

import useVuelidate from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
import VenueLocations from "@/models/venue-locations";

export default defineComponent({
  name: "VenueEdition",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BaseField,
    BaseButton,
  },
  props: {
    updating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: Object as PropType<VenueData>,
      required: true,
    },
  },
  watch: {
    "value.country": function () {
      this.value.state = this.states[0];
    },
    "value.state": function () {
      this.value.city = "";
    },
  },
  emits: ["update:modelValue", "publishVenue"],
  computed: {
    value: {
      get(): VenueData {
        return this.modelValue;
      },
      set(value: VenueData): void {
        this.$emit("update:modelValue", value);
      },
    },
    leadership: {
      get(): string {
        return this.value.leadership ? this.value.leadership.join("; ") : "";
      },
      set(value: string): void {
        this.value.leadership = value
          ? value.split(";").map(Function.prototype.call, String.prototype.trim)
          : [];
      },
    },
    countries() {
      return VenueLocations.countries;
    },
    states(): Array<string> {
      const country = this.value.country;
      const statesArray: Array<string> = VenueLocations.getStates(country);
      return statesArray;
    },
    buttonText(): string {
      return this.updating ? "Actualizar" : "Agregar";
    },
  },
  methods: {
    handleAction(event: Event): void {
      event.preventDefault();
      this.$emit("publishVenue", this.updating);
    },
    formatLeadership(): string | undefined {
      if (this.value.leadership) {
        return this.value.leadership.join("; ");
      } else {
        return undefined;
      }
    },
  },
  validations() {
    const validPhone = () => {
      const phoneLength = this.value.phone.length;
      return 7 <= phoneLength && phoneLength <= 11;
    };
    const validLatitude = () => {
      const latitude = this.value.latitude;
      return isFinite(latitude) && Math.abs(latitude) <= 90;
    };
    const validLongitude = () => {
      const longitude = this.value.longitude;
      return isFinite(longitude) && Math.abs(longitude) <= 180;
    };
    const validLeadership = () =>
      this.value.leadership ? this.value.leadership.length > 0 : false;
    // const atLeastOneMethod = () => this.haveSelectedDistribution();
    return {
      value: {
        name: { required },
        leadership: { validLeadership },
        address: { required },
        country: { required },
        state: { required },
        city: { required },
        phone: { validPhone },
        website: { required, url },
        latitude: { validLatitude },
        longitude: { validLongitude },
      },
    };
  },
});
